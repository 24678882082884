<template>
  <div class="join center">
    <div class="baseBox">
      <div class="joinBox">
        <h2>비밀번호 재설정</h2>
        <div class="form">
          <div class="flex">
            <p>
              비밀번호
              <span>*</span>
            </p>
            <div class="password">
              <input type="password" v-model="password" />
            </div>
          </div>
          <div class="flex">
            <p>
              비밀번호 재확인
              <span>*</span>
            </p>
            <div class="password">
              <input type="password" v-model="passwordConfirm" />
            </div>
          </div>
        </div>
        <div class="buttonWrap">
          <button class="point large" @click="submit">비밀번호 변경</button>
          <router-link to="/login">
            <button class="cancel large pointOut">취소</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { updatePwd } from "@/api/index";
import { mapState } from "vuex";
export default {
  mixins: [format],
  data() {
    return {
      password: "",
      passwordConfirm: "",
    };
  },
  computed: {
    ...mapState(["username", "mobileNo", "email"]),
  },
  methods: {
    submit() {
      if (this.password == "" || this.passwordConfirm == "") {
        return alert("비밀번호를 입력해주세요.");
      } else if (this.password != this.passwordConfirm) {
        return alert("비밀번호가 다릅니다.");
      }

      let data = {
        email: this.email,
        mobileNo: this.mobileNo,
        username: this.username,
        password: this.password,
      };
      updatePwd(data).then((res) => {
        if (res.data.status == 200) {
          this.$router.push("/changePasswordDone");
        } else {
          let message = res.data.message;
          alert(message);
        }
      });
    },
  },
};
</script>
